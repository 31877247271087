import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useCallback } from 'react';

let count = 0;

export const useBodyScrollLockCallbackRef = (ref: any) => useCallback((node: any) => {
  if (ref.current) {
    if (count > 0) {
      count -= 1;
    }

    if (count === 0) {
      document.body.style.overscrollBehaviorY = 'auto';
      document.querySelector('html')!.style.overscrollBehavior = 'auto';
      clearAllBodyScrollLocks();
    }
  }

  if (node) {
    document.body.style.overscrollBehaviorY = 'none';
    document.querySelector('html')!.style.overscrollBehavior = 'none';
    disableBodyScroll(node, {
      reserveScrollBarGap: true,
      allowTouchMove: (el) => el !== node,
    });
    count += 1;
  }

  // eslint-disable-next-line no-param-reassign
  ref.current = node;
}, []);
