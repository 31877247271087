import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

function useRoot() {
  const [ root, setRoot ] = useState<null | HTMLDivElement>(null);

  useEffect(() => {
    const rootEl = document.createElement('div');
    rootEl.setAttribute('data-id', 'portal-root');
    document.body.appendChild(rootEl);
    setRoot(rootEl);

    return () => {
      setRoot(null);
      document.body.removeChild(rootEl);
    };
  }, []);

  return root;
}

export const Portal: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const root = useRoot();

  return root && ReactDOM.createPortal(children, root);
};
